<template>
  <div>
    <div id="container" :style="'height:' + pmHeight + 'px;'"></div>
    <div class="search-box" v-if="this.tripInfo != null">
      <div class="list-item">
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div>
            <div style="font-size: 16px; font-weight: 600">
              {{ tripInfo.passenger.islong == 1 ? time2(tripInfo.passenger.tripDate) : '每天'}} {{ tripInfo.passenger.tripTime }}
            </div>
            <div v-if="userTypes == '2'">
              <span style="margin-right: 5px"
                ><van-tag type="primary"
                  >{{ tripInfo.passenger.passengerNum }}人</van-tag
                ></span
              >
              <span style="margin-right: 5px"
                ><van-tag type="success">{{
                  tripInfo.passenger.carpooling == 1 ? "愿拼座" : "不拼座"
                }}</van-tag></span
              >
              <span style="margin-right: 5px"
                ><van-tag type="danger">{{
                  tripInfo.passenger.luggage == 1 ? "无大件行李" : "有大件行李"
                }}</van-tag></span
              >
              <span><van-tag type="warning">{{ tripInfo.passenger.islong == 1 ? '单次行程' : '长期行程' }}</van-tag></span>
            </div>
            <div v-else>
              <span style="margin-right: 5px"
                ><van-tag type="primary"
                  >可坐{{ tripInfo.passenger.passengerNum }}人</van-tag
                ></span
              >
              <span style="margin-right: 5px"
                ><van-tag type="success">{{
                  formatCarNo(tripInfo.user.car_no)
                }}</van-tag></span
              >
            </div>
          </div>
          <div style="font-size: 22px; font-weight: 500" v-if="tripInfo.passenger.fee!=null">
            {{ tripInfo.passenger.fee
            }}<span style="font-size: 12px; margin-left: 2px">元</span>
          </div>
          <div style="font-size: 18px; font-weight: 600" v-else>
            面议
          </div>
        </div>
        <div
          style="
            margin-top: 15px;
            padding-bottom: 10px;
            /* border-bottom: 1px solid #f2f2f2; */
          "
        >
          <div style="display: flex; font-size: 12px">
            <div>
              <van-icon
                name="location-o"
                style="font-size: 16px; margin-right: 3px"
              />起点：
            </div>
            <div>{{ tripInfo.passenger.startAddress }}</div>
          </div>
          <div
            style="
              width: 95%;
              margin-left: 5%;
              height: 1px;
              /* background: #f2f2f2; */
              margin-top: 5px;
            "
          ></div>
          <div style="display: flex; font-size: 12px; margin-top: 5px" v-if="tjdInfo!=null">
            <div>
              <van-icon
                name="location-o"
                style="font-size: 16px; margin-right: 3px"
              />途经：
            </div>
            <div style="display:flex;flex-direction: column;"><div  v-for="(item,index) in tjdInfo" :key="index">{{ item.name }}</div></div>
          </div>
          <div style="display: flex; font-size: 12px; margin-top: 5px">
            <div>
              <van-icon
                name="location-o"
                style="font-size: 16px; margin-right: 3px"
              />终点：
            </div>
            <div>{{ tripInfo.passenger.endAddress }} <van-tag type="primary" v-if="drevingInfo!=null"
                  >全程{{(drevingInfo.routes[0].distance / 1000).toFixed(1)}}公里</van-tag
                ></div>
          </div>
          <div class="box-item4" style="margin-top:15px;">
                  <div>备注：{{tripInfo.passenger.remarks}}</div>
                  <!-- <div>{{tripInfo.remarks}}</div> -->
                  <!-- <div>①全院实行实名制就医。</div>
                  <div>
                      ②发热、发病前28天内有境外史或14天内中高风险地区旅居史、与新冠肺炎病例或无症状感染者有接触史、接触过来自疫情中高风险地区的发热和/或有新冠十大症状的患者，请先到就近医院发热门诊就诊。
                  </div>
                  <div>
                      ③所有人员来院应全程规范佩戴口罩，主动出示健康码+行程码，并配合流行病学调查及测温，非必要不陪诊。
                  </div> -->
              </div>
        </div>
      </div>
      <div class="next">取消同行</div>
    </div>
  </div>
</template>
<script>
import { Icon } from "vant";
import { passenger } from "@/api";
import AMap from "AMap"; // 引入高德地图
var driving;
export default {
  components: {
    VanIcon: Icon,
  },
  data() {
    return {
      map: null,
      autoComplete: null,
      keyword: null,
      searchList: [],
      pmHeight: 500,
      showPopup: false,
      pointInfo: null,
      drevingInfo: null,
      tripInfo: null,
      tjdInfo: null,
      userTypes: null
    };
  },
  mounted() {
    this.userTypes = JSON.parse(localStorage.getItem("user")).types
    this.pmHeight =(
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight) - 209;
    // this.tripInfo = JSON.parse(this.$route.query.info);
    // this.tjdInfo = JSON.parse(this.tripInfo.tjd);
    // console.log(this.tripInfo,'tjdInfo');
    // console.log(this.tjdInfo,'tjdInfo');
    this.getMyPassenger()
    
  },
  methods: {
    getMyPassenger() {
      let params = {}
      passenger.getMyPassenger(params).then(res => {
        if(res.data.code == 200) {
          this.tripInfo = res.data.data
          this.tjdInfo = JSON.parse(res.data.data.passenger.tjd)
          this.initMap();
        }
      })
    },
    formatCarNo(e) {
      const nBegin = e.substring(0,2)
      const nEnd = e.substring(e.length - 2, e.length)
      return nBegin + '***' + nEnd
    },
    time2(data) {
      if (data) {
        console.log(data, 123);
        console.log(this.dayjs().isSame(this.dayjs(data)), 222);
        if (data == this.dayjs().format("YYYY-MM-DD")) {
          return "今天";
        } else if (
          this.dayjs(data) > this.dayjs() &&
          this.dayjs(data) < this.dayjs().add(1, "day")
        ) {
          return "明天";
        } else {
          return this.dayjs(data).format("MM月DD日");
        }
      }
    },
    showList() {
      this.showPopup = true;
    },
    initMap() {
      var _this = this;
      const dom = document.getElementById("container");
      this.map = new AMap.Map(dom, {
        resizeEnable: true,
        zoom: 15,
        center: [120.246232, 29.986425],
        showLabel: true, //不显示地图文字标记, 终于可以配置了TAT
      });
      // // JSAPI 2.0 输入提示插件名称由 AMap.Autocomplete 变更为 AMap.AutoComplete 啦！
      // AMap.plugin('AMap.ToolBar',function(){//异步加载插件
      //     var toolbar = new AMap.ToolBar();
      //     _this.map.addControl(toolbar);
      // });
      if (driving) {
        driving.clear();
      }
      AMap.plugin("AMap.Driving", function () {
        driving = new AMap.Driving({
          // 驾车路线规划策略，AMap.DrivingPolicy.LEAST_TIME是最快捷模式
          policy: AMap.DrivingPolicy.LEAST_DISTANCE,
          // map 指定将路线规划方案绘制到对应的AMap.Map对象上
          map: _this.map,
          // panel 指定将结构化的路线详情数据显示的对应的DOM上，传入值需是DOM的ID
          // panel: 'container'
        });
        
        var startLngLat = [_this.tripInfo.passenger.startLng * 1, _this.tripInfo.passenger.startLat * 1];
        var endLngLat = [_this.tripInfo.passenger.endLng * 1, _this.tripInfo.passenger.endLat * 1];
        let ls = []
        if(_this.tjdInfo!=null) {
          for(let i in _this.tjdInfo) {
            if(_this.tjdInfo[i].location.length == 2) {
                ls.push(new AMap.LngLat(_this.tjdInfo[i].location[0], _this.tjdInfo[i].location[1]))
              } else {
                ls.push(new AMap.LngLat(_this.tjdInfo[i].location.lng, _this.tjdInfo[i].location.lat))
              }
          }
        }
        
        var opts = {
          waypoints: ls
        }

        console.log(startLngLat);
        console.log(endLngLat);
        driving.search(startLngLat, endLngLat,  opts, function (status, result) {
          // 未出错时，result即是对应的路线规划方案
          console.log(status);
          console.log(result);
          _this.drevingInfo = result;
        });
        // _this.showPopup = false
      });
    },
  },
};
</script>
<style  scoped>
  .box-item4 {
  /* height: 121px; */
  /* width: 92%;
  margin-left: 4%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border-bottom: 2px solid #f2f2f2; */
  background: rgba(236, 165, 48, 0.16);
  padding: 10px;
  border-radius: 5px;
  color: #eca530;
}
.list-item {
  width: 100%;
  /* margin-left: 4%; */
  background: #fff;
  box-shadow: 0px 2px 10px -4px rgba(2, 66, 58, 0.2);
  border-radius: 8px;
  padding: 15px 15px 10px 15px;
  margin-bottom: 15px;
}
.cklx {
  border: 1px solid #2d83e8;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  color: #2d83e8;
}
#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 600px;
}
.search-box1 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  background: #fff;
  height: 60px;
  align-items: center;
  z-index: 99999999999;
}
.search-box {
  position: fixed;
  left: 0;
  bottom: 0px;
  width: 100%;
  display: flex;
  background: #fff;
  min-height: 60px;
  flex-direction: column;
  /* align-items: center; */
  z-index: 99999999999;
}
.search-box-one {
  position: relative;
  width: 90%;
  margin-left: 5%;
  height: 40px;
  border: 2px solid #f2f2f2;
  border-radius: 40px;
  display: flex;
  align-items: center;
}
.input-sty {
  width: 80%;
  margin-left: 2%;
  height: 35px;
  line-height: 35px;
  border: none;
}
.search-icon {
  font-size: 20px;
  margin-left: 10px;
}
.address-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #f2f2f2;
}
.gdt::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.next {
  width: 90%;
  margin-left: 5%;
  height: 39px;
  background: #2d83e8;
  margin-top: 5px;
  border-radius: 40px;
  text-align: center;
  line-height: 39px;
  color: #fff;
  font-size: 16px;
  margin-bottom: 20px;
}
</style>